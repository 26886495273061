import React from "react";
import { graphql } from "gatsby";
import SEO from "../../components/SEO";
import Layout from "../../layouts/index";
import img1 from "../../../static/software/software-1.png";
import img2 from "../../../static/software/software-2.png";
import img3 from "../../../static/software/software-3.png";

const Software = (props) => {
  const path = props.uri;
  const json = props.data.allSoftwareJson.edges;

  const erp = json[0].node;
  const financial = json[1].node;
  const managment = json[2].node;

  return (
    <Layout bodyClass="page-solutions" path={path}>
      <SEO title="Software" />
      <div className="container pt-8 pt-md-10" id={erp.path}>
        <div className="row mt-2 mt-md-4">
          <div className="col-12 text-center">
            <h5 className="font-italic">{erp.subtitle}</h5>
            <h1>{erp.title}</h1>
          </div>
        </div>
        <div className="row mt-2 mt-md-4 mb-2 mb-md-4 align-items-center">
          <div className="col-12 mt-2 mt-md-4 mb-2 mb-md-4">
            <div className="d-flex justify-content-center">
              <img
                src={img1}
                alt={erp.title}
                className="mockup-image"
                data-sal="slide-up"
                data-sal-duration="1000"
              />
            </div>
          </div>
          <div className="col-12 p-0 p-md-x-1">
            <div className="main-card">
              <div className="card-skew" />
              <div className="card-content">
                <p>{erp.intro}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-12 col-md-4 mt-2 mb-2">
            <div className="list-card">
              <div className="list-title w-100">
                <h3 className="mb-0">{erp.list1Title}</h3>
              </div>
              <div className="list-content w-100">
                <div className="list">
                  {erp.list1.map((listItem) => (
                    <div
                      className="list-item compact"
                      key={Math.random() * 10}
                      data-sal="zoom-in"
                      data-sal-duration="800"
                    >
                      <h3 className="mb-0">{listItem}</h3>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 mt-2 mb-2">
            <div className="list-card">
              <div className="list-title w-100">
                <h3 className="mb-0">{erp.list2Title}</h3>
              </div>
              <div className="list-content w-100">
                <div className="list">
                  {erp.list2.map((listItem) => (
                    <div
                      className="list-item compact"
                      key={Math.random() * 20}
                      data-sal="zoom-in"
                      data-sal-duration="800"
                    >
                      <h3 className="mb-0">{listItem}</h3>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 mt-2 mb-2">
            <div className="list-card">
              <div className="list-title w-100">
                <h3 className="mb-0">{erp.list3Title}</h3>
              </div>
              <div className="list-content w-100">
                <div className="list">
                  {erp.list3.map((listItem) => (
                    <div
                      className="list-item compact"
                      key={Math.random() * 30}
                      data-sal="zoom-in"
                      data-sal-duration="800"
                    >
                      <h3 className="mb-0">{listItem}</h3>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container pt-4 pt-md-10" id={financial.path}>
        <div className="row">
          <div className="col-12 text-center">
            <h5 className="font-italic">{financial.subtitle}</h5>
            <h1>{financial.title}</h1>
          </div>
        </div>
        <div className="row mt-2 mt-md-4 mb-2 mb-md-4 align-items-center">
          <div className="col-12 mt-2 mt-md-4 mb-2 mb-md-4 justify-content-center text-center">
            <img
              src={img2}
              alt={financial.title}
              className="mockup-image"
              data-sal="slide-up"
              data-sal-duration="1000"
            />
          </div>
          <div className="col-12 p-0 p-md-x-1">
            <div className="main-card">
              <div className="card-skew" />
              <div className="card-content">
                <p>{financial.introArray[0]}</p>
                <br />
                <p>{financial.introArray[1]}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-12 col-md-4 mt-2 mb-2">
            <div className="list-card">
              <div className="list-title w-100">
                <h3 className="mb-0">{financial.list1Title}</h3>
              </div>
              <div className="list-content w-100">
                <div className="list">
                  {financial.list1.map((listItem) => (
                    <div
                      className="list-item compact"
                      key={Math.random() * 10}
                      data-sal="zoom-in"
                      data-sal-duration="800"
                    >
                      <h3 className="mb-0">{listItem}</h3>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 mt-2 mb-2">
            <div className="list-card">
              <div className="list-title w-100">
                <h3 className="mb-0">{financial.list2Title}</h3>
              </div>
              <div className="list-content w-100">
                <div className="list">
                  {financial.list2.map((listItem) => (
                    <div
                      className="list-item compact"
                      key={Math.random() * 20}
                      data-sal="zoom-in"
                      data-sal-duration="800"
                    >
                      <h3 className="mb-0">{listItem}</h3>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 mt-2 mb-2">
            <div className="list-card">
              <div className="list-title w-100">
                <h3 className="mb-0">{financial.list3Title}</h3>
              </div>
              <div className="list-content w-100">
                <div className="list">
                  {financial.list3.map((listItem) => (
                    <div
                      className="list-item compact"
                      key={Math.random() * 30}
                      data-sal="zoom-in"
                      data-sal-duration="800"
                    >
                      <h3 className="mb-0">{listItem}</h3>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container pt-4 pt-md-10" id={managment.path}>
        <div className="row mt-2 mt-md-4">
          <div className="col-12 text-center">
            <h5 className="font-italic">{managment.subtitle}</h5>
            <h1>{managment.title}</h1>
          </div>
        </div>

        <div className="row mt-2 mt-md-4 mb-2 mb-md-4 align-items-center">
          <div className="col-12 mt-2 mt-md-4 mb-2 mb-md-4">
            <div className="d-flex justify-content-center">
              <img
                src={img3}
                alt={managment.title}
                className="mockup-image"
                data-sal="slide-up"
                data-sal-duration="1000"
              />
            </div>
          </div>
          <div className="col-12 p-0 p-md-x-1">
            <div className="main-card">
              <div className="card-skew" />
              <div className="card-content">
                <p>{managment.intro}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-12 col-md-4 mt-2 mb-2">
            <div className="list-card">
              <div className="list-title w-100">
                <h3 className="mb-0">{managment.list1Title}</h3>
              </div>
              <div className="list-content w-100">
                <div className="list">
                  {managment.list1.map((listItem) => (
                    <div
                      className="list-item compact"
                      key={Math.random() * 10}
                      data-sal="zoom-in"
                      data-sal-duration="800"
                    >
                      <h3 className="mb-0">{listItem}</h3>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 mt-2 mb-2">
            <div className="list-card">
              <div className="list-title w-100">
                <h3 className="mb-0">{managment.list2Title}</h3>
              </div>
              <div className="list-content w-100">
                <div className="list">
                  {managment.list2.map((listItem) => (
                    <div
                      className="list-item compact"
                      key={Math.random() * 20}
                      data-sal="zoom-in"
                      data-sal-duration="800"
                    >
                      <h3 className="mb-0">{listItem}</h3>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 mt-2 mb-2">
            <div className="list-card">
              <div className="list-title w-100">
                <h3 className="mb-0">{managment.list3Title}</h3>
              </div>
              <div className="list-content w-100">
                <div className="list">
                  {managment.list3.map((listItem) => (
                    <div
                      className="list-item compact"
                      key={Math.random() * 30}
                      data-sal="zoom-in"
                      data-sal-duration="800"
                    >
                      <h3 className="mb-0">{listItem}</h3>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allSoftwareJson {
      edges {
        node {
          title
          subtitle
          path
          list3Title
          list3
          list2Title
          list2
          list1Title
          list1
          id
          intro
          introArray
        }
      }
    }
  }
`;

export default Software;
